import { useQuery } from 'react-query';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import {
  getHsaEligibility,
  HSA_ELIGIBILITY_STATUS,
} from 'api/get-hsa-eligibility';
import { useCurrentRegionUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { useGetIdentityPartnerToken } from './use-get-identity-partner-token';

const QUERY_CACHE_AND_STALE_TIME = 5 * 1000 * 60;

export type HsaRegistrationData = {
  isHsaRegistrationEligible: boolean;
  memberId?: string;
  groupNumber?: string;
  clientNumber?: string;
  status?: 'Eligible' | 'Pending';
};

export const useGetHsaRegistrationEligibility = () => {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const ubkHeader = useCurrentRegionUbkHeader();

  const { data, isLoading, isIdle } = useQuery(
    [QUERY_KEYS.HSA_ELIGIBILITY_KEY],
    () => getHsaEligibility(identityTokenData, ubkHeader),
    {
      enabled: !!identityTokenData,
      staleTime: QUERY_CACHE_AND_STALE_TIME,
      cacheTime: QUERY_CACHE_AND_STALE_TIME,
    },
  );

  // user is eligible if exactly 1 client listed (ineligible for 0 or multiple clients)
  const targetClient = data?.clients?.length === 1 ? data.clients[0] : null;

  // user must have eligible status and not already be registered to be considered eligible
  const hsaRegistrationEligibility: HsaRegistrationData = targetClient
    ? {
        isHsaRegistrationEligible:
          targetClient.status === HSA_ELIGIBILITY_STATUS.ELIGIBLE &&
          !targetClient.isRegistered,
        status: targetClient.status,
        memberId: data?.memberId,
        groupNumber: targetClient.groupNumber,
        clientNumber: targetClient.clientNumber,
      }
    : { isHsaRegistrationEligible: false };
  return { data: hsaRegistrationEligibility, isLoading, isIdle };
};
