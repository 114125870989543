import { forwardRef } from 'react';
import { GDSStyleObject, Radio } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export type RadioRowInputProps = {
  id: string;
  name: string;
  label: string;
  value?: string;
  css?: GDSStyleObject;
};

export const RadioRowInput = forwardRef<HTMLInputElement, RadioRowInputProps>(
  ({ id, name, label, value, css = {}, ...props }, ref) => {
    const { formatMessage } = useIntl();

    return (
      <Radio
        ref={ref}
        id={id}
        name={name}
        value={value || undefined}
        label={formatMessage({ id: label })}
        layout="row"
        css={{ '.GDS-radio-label': { fontSize: '1.4rem' }, ...css }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
);
