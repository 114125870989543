import { DropdownSection } from '@leagueplatform/web-common-components';
import { LinkHandler } from 'utils/link-handler';
import { useTestConfigFlags } from 'hooks/use-get-test-config-flags.hook';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name';
import { buildSsoUrl } from 'utils/sso-url-helpers';
import { LINK_TYPES, SSO_BASE_URL_TYPES } from 'common/sso-constants';

export type UserDropdownProps = {
  insuranceAdministeredByThirdParty: boolean;
  isCobrowseEnabled?: boolean;
  isCobSdkEnabled?: boolean;
  isBackgroundInformationEnabled: boolean;
};

export const useUserDropdown = (userDropdownConfig: DropdownSection[]) => {
  const { data, isLoading, error } = usePreferredFirstName();

  // For testing purposes only, available only in staging/dev envs
  let userDropdownConfigResult = userDropdownConfig;

  const { testSsoLink } = useTestConfigFlags();
  if (testSsoLink) {
    const testLinks = [
      {
        text: `SSO Link: ${testSsoLink}`,
        onClick: () => {
          LinkHandler.handleLink(
            buildSsoUrl({
              path: testSsoLink,
              linkType: LINK_TYPES.SSO,
              baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
            }),
          );
        },
      },
    ];
    userDropdownConfigResult = [testLinks, ...userDropdownConfig];
  }

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: userDropdownConfigResult,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
