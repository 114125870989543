import * as React from 'react';
import {
  FEATURE_FLAGS,
  Navbar,
  useGetAppInitDetails,
  useInjectCobrowseScript,
} from '@highmark-web/highmark-common';
import { getUserDropdownConfig } from 'utils/get-user-dropdown-config';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { useGenesisCoreInspector } from '@leagueplatform/genesis-core';

export const AppNavBarComponent = React.memo(() => {
  const { qualifiers, brandingInfo } = useGetAppInitDetails();
  const { isEnabled: isCobrowseEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.USER_DROPDOWN_COBROWSE,
  );
  const { isEnabled: isCobSdkEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.COB_SDK,
  );
  const { isEnabled: isBackgroundInformationEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.BACKGROUND_INFORMATION,
  );

  useInjectCobrowseScript(isCobrowseEnabled);
  const userDropdownConfig = getUserDropdownConfig({
    ...qualifiers,
    isCobrowseEnabled,
    isCobSdkEnabled,
    isBackgroundInformationEnabled,
  });

  // Inject the Genesis Core Inspector tool when feature flag is enabled.
  const { isEnabled: isGenesisCoreInspectorEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.ENABLE_GENESIS_CORE_INSPECTOR,
  );
  const { isEnabled: isGenesisCoreInspectorActive, toggleInspector } =
    useGenesisCoreInspector();
  if (isGenesisCoreInspectorEnabled) {
    userDropdownConfig.unshift([
      {
        text: isGenesisCoreInspectorActive
          ? 'DISABLE_GENESIS_CORE_INSPECTOR'
          : 'ENABLE_GENESIS_CORE_INSPECTOR',
        to: '',
        onClick: () => {
          toggleInspector();
        },
      },
    ]);
  }

  return (
    <Navbar
      userDropdownConfig={userDropdownConfig}
      brandingInfo={brandingInfo}
    />
  );
});
