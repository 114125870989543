import { useState } from 'react';
import { useGetAppInitDetails } from 'hooks/use-get-app-init-details';
import { useGetHighmarkUserProfile } from 'hooks/use-get-user-profile';
import { useTestConfigFlags } from 'hooks/use-get-test-config-flags.hook';
import { captureUserIneligibleEvent } from 'utils/sentry-helpers';
import { EligibilityInfo, PageIneligibility } from 'api/get-app-init-details';
import { RegionSelection } from 'components/header-nav/region-selection-context';

export type UsePrivateRoutesContainerReturn = {
  contentConfig: PageIneligibility;
  regionSelection: RegionSelection;
  eligibilityInfo: EligibilityInfo;
  showWalkthrough: boolean;
  onCompleteWalkthrough: () => void;
  showMfa: boolean;
  onCompleteMfa: () => void;
  isLoading: boolean;
  isErrorAppInitDetails: boolean;
  isErrorUserProfile: boolean;
};
export const usePrivateRoutesContainer =
  (): UsePrivateRoutesContainerReturn => {
    const [walkthroughCompleted, setWalkthroughCompleted] = useState(false);
    const [mfaCompleted, setMfaCompleted] = useState(false);
    const { forceAppWalkthrough } = useTestConfigFlags();

    const {
      eligibilityInfo,
      mfaEnrolled,
      contentConfig,
      currentUbk,
      eligibilityOptions,
      isLoading: isLoadingAppInitdetails,
      isError: isErrorAppInitDetails,
    } = useGetAppInitDetails({
      onSuccess: captureUserIneligibleEvent,
    });

    const {
      data: userData,
      isLoading: isLoadingUserProfile,
      isError: isErrorUserProfile,
    } = useGetHighmarkUserProfile();

    const hasUserAcceptedTerms =
      userData?.userProfile?.memberTermsAndConditions?.dateAccepted || false;

    // If forceAppWalkthrough test flag is set, or if user has not accepted terms
    // then, show walkthrough on initial load.
    const showWalkthrough: boolean =
      (forceAppWalkthrough === 'true' || !hasUserAcceptedTerms) &&
      !walkthroughCompleted;

    const showMfa = !mfaEnrolled && !mfaCompleted;

    const regionSelectionData: RegionSelection = {
      selectedRegion: currentUbk,
      regionOptions: eligibilityOptions,
    };

    return {
      contentConfig,
      eligibilityInfo,
      regionSelection: regionSelectionData,
      showWalkthrough,
      onCompleteWalkthrough: () => setWalkthroughCompleted(true),
      showMfa,
      onCompleteMfa: () => setMfaCompleted(true),
      isLoading: isLoadingAppInitdetails || isLoadingUserProfile,
      isErrorAppInitDetails,
      isErrorUserProfile,
    };
  };
