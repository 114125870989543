import React, { forwardRef } from 'react';
import { Radio } from '@leagueplatform/genesis-core';

export type RegionRadioInputProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  hint: string;
};

export const RegionRadioInput = forwardRef<
  HTMLInputElement,
  RegionRadioInputProps
>(({ id, name, label, value, hint = '', ...props }, ref) => (
  <Radio
    ref={ref}
    id={id}
    name={name}
    value={value}
    hint={`${hint[0].toUpperCase()}${hint.slice(1)}`}
    label={label}
    layout="row"
    css={{
      paddingY: '$half',
      borderBottom: '1px solid $onSurfaceBorderSubdued',
    }}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
));
