import {
  AuthConfig,
  AuthlessLeagueConfig,
  DynamicSdkConfig,
  LinkHandler,
  LoadingSpinner,
  MASONRY,
  useLeagueConfigFirebaseUpdater,
} from '@highmark-web/highmark-common';
import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
import React from 'react';
import { getWalletConfig } from 'utils/get-wallet-config.util';
import { EN_STRING_OVERRIDES } from '../locales/sdk-string-overrides/en';
import { iELTheme } from '../theme/theme';
import { ASSETS_CONFIG } from './asset-config';
import {
  CLIENT_ID,
  APP_STORE_PROD_URL,
  PLAY_STORE_PROD_URL,
  TENANT_ID,
} from '../common/constants';
import { getClaimsConfig } from '../utils/get-claims-config.util';
import { getLiveChatConfig } from '../utils/get-live-chat-config.util';
import { firebaseRemoteConfig } from '../common/firebase/firebase';

// Content Server Configuration to support multiple domains on prod, so content server url matches the same domain
// TODO: We don't need this after migration is complete: https://everlong.atlassian.net/browse/HMRK-3918
let contentServerUrl = import.meta.env.VITE_CONTENT_SERVER_URL as string;
if (import.meta.env.VITE_ENV === ENVIRONMENT.PRODUCTION) {
  // Gets the base domain e.g. something.highmark.com -> highmark.com
  const baseDomain = window.location.hostname.split('.').slice(-2).join('.');
  contentServerUrl = `https://content.${baseDomain}`;
}

// SDK Initializers that use League's Config API
const AUTHLESS_LEAGUE_CONFIG: AuthlessLeagueConfig = {
  core: {
    clientId: CLIENT_ID,
    tenantId: TENANT_ID,
    // We do not set `auth` yet - it will be added dynamically once we know whether we're in standalone or embedded (mobile auth) mode
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    contentUrl: contentServerUrl,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,
    analytics: {
      segmentKey: import.meta.env.VITE_SEGMENT,
    },
    customMethods: {
      handleLink: LinkHandler.handleLink,
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    mobileAppUrls: {
      appStoreUrl: APP_STORE_PROD_URL,
      playStoreUrl: PLAY_STORE_PROD_URL,
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
    ui: {
      theme: iELTheme,
      components: {
        loading: { element: <LoadingSpinner />, text: false },
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
  aboutMe: {
    showAccountBanner: false,
    showLandingPageBackButton: true,
  },

  careDiscovery: {
    masonryAppId: {
      left: MASONRY.APP_ID_CARE_DISCOVERY_HOME,
    },
  },
  healthProfile: {
    ratingsBarEnabled: false,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: false,
  },
  __care__adjustForCardColorThemeDiscrepancy: true,
} as AuthlessLeagueConfig;

const getDynamicSdkConfig = (): DynamicSdkConfig => ({
  ...{},
  claims: getClaimsConfig(),
  wallet: getWalletConfig(),
  liveChat: getLiveChatConfig(),
});

export const useGetLeagueConfig = (
  authConfig: AuthConfig | undefined,
): LeagueConfig => {
  const sdkConfigWithRemoteFlagValues = useLeagueConfigFirebaseUpdater(
    firebaseRemoteConfig,
    getDynamicSdkConfig,
  );

  return {
    ...AUTHLESS_LEAGUE_CONFIG,
    core: {
      ...AUTHLESS_LEAGUE_CONFIG.core,
      auth: authConfig,
    } as LeagueConfig['core'],
    ...sdkConfigWithRemoteFlagValues,
  };
};
