import { initializeApp } from 'firebase/app';
import { RemoteConfig } from 'firebase/remote-config';
import {
  FEATURE_FLAGS,
  initFirebaseRemoteConfig,
} from '@highmark-web/highmark-common';

// Firebase config constants
const firebaseConfig = {
  apiKey: 'AIzaSyAr1iKva09Q9ZjouKX-s1uGi29JdVhnUdo',
  authDomain: 'league-hm-firebase-iel.firebaseapp.com',
  projectId: 'league-hm-firebase-iel',
  storageBucket: 'league-hm-firebase-iel.appspot.com',
  messagingSenderId: '625980287721',
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

// In-app default values for feature flags - fallback values in case of network errors
export const FIREBASE_DEFAULT_VALUES = {
  [FEATURE_FLAGS.PLACEHOLDER_FLAG]: false, // placeholder flag (do not remove)
  /* Add default values for active flags below this line */
  [FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_CLAIM_DETAILS]: false,
  [FEATURE_FLAGS.CLAIMS_ENABLE_DYNAMIC_FILTERS]: false,
  [FEATURE_FLAGS.USER_DROPDOWN_COBROWSE]: false,
  [FEATURE_FLAGS.CMS_CODE]: '',
  [FEATURE_FLAGS.COB_SDK]: false,
  [FEATURE_FLAGS.WALLET_ENABLE_NO_NETWORK_LIVE_BALANCES]: false,
  [FEATURE_FLAGS.ENABLE_GENESIS_CORE_INSPECTOR]: false,
  [FEATURE_FLAGS.LIVE_CHAT_NAVIGATE_AWAY]: false,
};

export const firebaseRemoteConfig: RemoteConfig = initFirebaseRemoteConfig({
  firebaseApp,
  defaultValues: FIREBASE_DEFAULT_VALUES,
  // *NOTE: Uncomment the following line for development/debugging
  // minimumFetchIntervalMillis: 60 * 1000, // 1 min
});
