import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { MASONRY } from 'common/constants';
import { RichTextSection } from 'components/masonry-rich-text/masonry-rich-text.component';
import { PwpHeader } from 'components/pwp/pwp-header.component';
import { PwpPrintButton } from 'components/pwp/pwp-pdf-print-button.component';
import { PWPDisclaimerWidget } from 'pages/pwp/pwp-disclaimer-widget.component';

export const initMasonryRegistry = () => {
  // Registering overviewText node type to Masonry WS Driver
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    MASONRY.NAMESPACE_HIGHMARK,
    MASONRY.NODE_TYPE_OVERVIEW_TEXT,
    RichTextSection,
  );
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    MASONRY.NAMESPACE_HIGHMARK,
    MASONRY.CUSTOM_WIDGET_TYPE_PWP_HEADER,
    PwpHeader,
  );
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    MASONRY.NAMESPACE_HIGHMARK,
    MASONRY.NODE_TYPE_BUTTON_SECONDARY,
    PwpPrintButton,
  );

  // Registering disclaimer banner node type to Masonry WS Driver
  MasonryWSDriverNodeRenderersRegistry.registerRenderer(
    MASONRY.NAMESPACE_HIGHMARK,
    'pwp_disclaimer',
    PWPDisclaimerWidget,
  );
};
