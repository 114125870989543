import * as React from 'react';

import { BoxProps } from '@leagueplatform/genesis-commons';
import { Box, queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import {
  APP_NAV_DEFAULT_HEIGHT,
  APP_NAV_MOBILE_HEIGHT,
  AuthedWebviewModal,
  ERROR_TYPES,
  ErrorState,
  LinkHandler,
  LoadingSpinner,
  QueryParamModal,
  useSsoDeepLink,
  LiveChat,
  FEATURE_FLAGS,
} from '@highmark-web/highmark-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { AppNavBarComponent } from './nav.component';
import { IelFooter } from './footer/iel-footer.component';

export const PrivateBasePage: React.FC<BoxProps> = ({ children }) => {
  useScrollTopOnLocationChange();
  useSsoDeepLink(LinkHandler);

  const { isEnabled: isLiveChatNavigateAwayEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.LIVE_CHAT_NAVIGATE_AWAY,
  );

  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const MAIN_CONTENT_MIN_HEIGHT = `calc(100vh - ${
    isMobile ? APP_NAV_MOBILE_HEIGHT : APP_NAV_DEFAULT_HEIGHT
  }px)`;

  return (
    <>
      <AppNavBarComponent />
      <AuthedWebviewModal linkHandler={LinkHandler} />
      <QueryParamModal />
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          flex: '1',
          overflowX: 'hidden',
          // This is so any page will take up the whole screen regardless of content
          minHeight: MAIN_CONTENT_MIN_HEIGHT,
        }}
      >
        <ObservabilityErrorBoundary
          fallback={
            <ErrorState
              errorType={ERROR_TYPES.API_ERROR}
              styleProps={{
                minHeight: MAIN_CONTENT_MIN_HEIGHT,
              }}
            />
          }
          errorContext={{
            context: {
              Context: { location: 'BasePage ErrorBoundary' },
            },
          }}
        >
          <React.Suspense fallback={<LoadingSpinner />}>
            {children}
          </React.Suspense>
        </ObservabilityErrorBoundary>
      </Box>
      <IelFooter />
      {isLiveChatNavigateAwayEnabled ? <LiveChat /> : null}
    </>
  );
};
