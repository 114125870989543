import { Route, useRouteMatch } from '@leagueplatform/routing';
import { isProduction } from '@leagueplatform/core';
import { PageNotFound } from '@leagueplatform/web-common-components';
import { PWPMainPage } from './pwp-main.page';

/**
 * Medicare Advantage Personalized Wellness Plan Routes
 */
export const PWPRoutes = () => {
  const { path } = useRouteMatch();
  const isProd = isProduction();

  // TODO: Remove isProd check when feature is ready
  if (isProd) {
    return <PageNotFound />;
  }

  return (
    <Route path={`${path}`}>
      <PWPMainPage />
    </Route>
  );
};
