import { useStaticAsset } from '@leagueplatform/asset-config';
import { GDSStyleObject } from '@leagueplatform/genesis-core';
import { THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS } from 'common/constants';
import { HIGHMARK_COMMON_ASSET_KEYS } from 'types/highmark-common.types';
import React from 'react';
import { RegionSelectionModal } from 'components/region-selection/region-selection-modal.component';

export enum MODAL_PARAMS {
  AMWELL_UNDER_18 = 'amwellunder18',
  LEAVE_SITE_YMCA = 'ymcaexit',
  LEAVE_SITE_LIVONGO = 'livongoexit',
  SWITCH_REGION = 'switchregion',
}

export const VALID_MODAL_PARAMS = [
  MODAL_PARAMS.AMWELL_UNDER_18,
  MODAL_PARAMS.LEAVE_SITE_LIVONGO,
  MODAL_PARAMS.LEAVE_SITE_YMCA,
];

export const VALID_CUSTOM_MODAL_PARAMS = [MODAL_PARAMS.SWITCH_REGION];

export type QueryModalContent = {
  label?: string;
  image?: string;
  heading?: string;
  body?: string;
  bodyTwo?: string;
  cancelButton?: {
    id: string;
    onClick?: () => void;
  };
  submitButton?: {
    id: string;
    onClick?: () => void;
  };
  styling?: GDSStyleObject;
  customComponent?: React.FunctionComponent<{ onClose: () => void }>;
};

export const useQueryModalContent = (type: MODAL_PARAMS): QueryModalContent => {
  const leaveSiteImage = useStaticAsset(
    HIGHMARK_COMMON_ASSET_KEYS.HIGHMARK_EXIT_WARNING_IMAGE,
  ) as string;

  switch (type) {
    case MODAL_PARAMS.AMWELL_UNDER_18:
      return {
        label: 'AMWELL_DISCLAIMER',
        heading: 'AMWELL_DISCLAIMER_HEADING',
        body: 'AMWELL_DISCLAIMER_BODY_1',
        bodyTwo: 'AMWELL_DISCLAIMER_BODY_2',
      };
    case MODAL_PARAMS.LEAVE_SITE_YMCA:
      return {
        label: 'LEAVE_SITE_DISCLAIMER',
        image: leaveSiteImage,
        heading: 'LEAVE_SITE_DISCLAIMER_HEADING_YMCA',
        body: 'LEAVE_SITE_DISCLAIMER_BODY',
        cancelButton: {
          id: 'CANCEL',
        },
        submitButton: {
          id: 'CONTINUE',
          onClick: () =>
            window.open(
              THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS.YMCA,
              '_blank',
              'noreferrer',
            ),
        },
        styling: {
          textAlign: 'center',
        },
      };
    case MODAL_PARAMS.LEAVE_SITE_LIVONGO:
      return {
        label: 'LEAVE_SITE_DISCLAIMER',
        image: leaveSiteImage,
        heading: 'LEAVE_SITE_DISCLAIMER_HEADING_LIVONGO',
        body: 'LEAVE_SITE_DISCLAIMER_BODY',
        cancelButton: {
          id: 'CANCEL',
        },
        submitButton: {
          id: 'CONTINUE',
          onClick: () =>
            window.open(
              THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS.LIVONGO,
              '_blank',
              'noreferrer',
            ),
        },
        styling: { textAlign: 'center' },
      };
    case MODAL_PARAMS.SWITCH_REGION:
      return {
        customComponent: RegionSelectionModal,
      };
    default:
      return {};
  }
};
