import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import {
  ANALYTICS_SCREEN_NAMES,
  DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH,
  MASONRY,
} from 'common/constants';
import {
  Box,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { BackButtonArrow } from 'components/back-button-arrow/back-button-arrow.component';
import { MasonryPageContainer } from 'pages/masonry/masonry-container.page';

const MasonryHomePageDriver = getMasonryWSDriver({
  appId: MASONRY.APP_ID_PWP_MAIN_PAGE,
  screenName: ANALYTICS_SCREEN_NAMES.PWP,
  productArea: PRODUCT_AREA.JOURNEY,
});

/**
 * Medicare Advantage Personalized Wellness Plan Main Page
 */
export const PWPMainPage = () => {
  usePageViewAnalytics({ screen_name: ANALYTICS_SCREEN_NAMES.PWP });
  const baseRoute = getModulePath(LEAGUE_MODULE_NAMES.healthJourney);

  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  return (
    <MasonryPageContainer>
      <StackLayout
        orientation={isMobile ? 'vertical' : 'horizontal'}
        spacing="$one"
        css={{
          marginTop: '$three',
          '@mobile': { marginTop: '$two' },
          '@mobileLandscape': { marginTop: '$two' },
        }}
      >
        <Box css={{ marginBlock: '$half' }}>
          <BackButtonArrow fallbackPath={baseRoute} />
        </Box>

        <Box css={{ maxWidth: DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH }}>
          <MasonryEngine driver={MasonryHomePageDriver} />
        </Box>
      </StackLayout>
    </MasonryPageContainer>
  );
};
